import React from 'react';
import Logo from '../../../static/logoIkom.png';


const HeaderLogin = () => {
    return (
        <div className="ikomLoginHeader text-center py-5">
            <img className="logoIkom" src={Logo} alt="logoIkom" />
        </div>
    );
}

export default HeaderLogin;