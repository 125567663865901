import React from 'react';
import HeaderLogin from '../components/Header/HeaderLogin';
// import Footer from '../components/Footer/Footer';

const LoginLayout = props => {
    return (
        <div className="loginLayout">
            <HeaderLogin />
                {props.children}
        </div>
    );
}

export default LoginLayout;