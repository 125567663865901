import React, { useContext, useState } from "react"
import { Form, Button } from "react-bootstrap"

// Context
import { GlobalContext } from "../../context/GlobalContext"

const LoginForm = () => {
  const { Authentication, isLoading, isError } = useContext(GlobalContext);

  const [isUsername, setIsUsername] = useState()
  const [isPassword, setIsPassword] = useState()
  const [isWebsite, setIsWebsite] = useState()

  const handleInput = e => {
    switch (e.target.name) {
      case "Username": setIsUsername(e.target.value); break;
      case "Password": setIsPassword(e.target.value); break;
      case "Website": setIsWebsite(e.target.value); break;
      default: break;
    }
  }

  const handleSubmit = e => {
      e.preventDefault();
      Authentication(isUsername, isPassword, isWebsite);
  }

  return (
    <div className="loginForm">
      <Form onSubmit={handleSubmit} className="w-25 mx-auto">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control
            onChange={handleInput}
            name="Username"
            type="text"
            placeholder="Enter Username"
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            onChange={handleInput}
            name="Password"
            type="password"
            placeholder="Password"
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Seleziona il Sito</Form.Label>
          <Form.Control onChange={handleInput} name="Website" as="select">
            <option>Seleziona...</option>
            <option value="Crimelondon">Crimelondon</option>
            <option value="Run2Me">Run2Me</option>
            <option value="Presidents">Presidents</option>
            <option value="CavalleriaToscana">Cavalleria Toscana</option>
            <option value="ottodAme">ottod'Ame</option>
          </Form.Control>
        </Form.Group>
        <Button className="w-100" variant="warning" type="submit">
          Invio
        </Button>
        {isLoading && <div className="py-3">Caricamento, attendere prego.</div>}
        {isError && <div className="py-3">Si è verificato un errore, inserisci le credenziali corrette.</div>}
      </Form>
    </div>
  )
}

export default LoginForm
