import React from "react";
import LoginLayout from '../layouts/LoginLayout';

// Components
import LoginForm from '../components/LoginForm/LoginForm';
import Title from "../components/Title/Title";

const Index = () => {
  return (
    <>
      <Title>Login</Title>
      <LoginLayout>
        <LoginForm />
      </LoginLayout>
    </>
  );
}

export default Index;
